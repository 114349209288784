import React, {useEffect, useState} from 'react';
import {Category} from "../../shared/types";
import {useNavigate} from "react-router-dom";
import styles from './categoriesPage.module.scss';

const CategoriesPage = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Category[]>([])
  
  useEffect(() => {
    (async () => {
      fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(  {
          command: "update_list_type",
          tmp_table: "tbl_type",
        })})
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setCategories(data)
      })
    })()
  }, [])
  return (
    <div className="container pt-[100px]">
      <div className={styles.headCard}>А1 ҚАРАПАЙЫМ ДЕҢГЕЙ</div>
      <div className="row">
        {categories.map((cat) => (
          <div key={cat.id} className="col-12 col-md-6 col-lg-4 mb-4">
            <div className={`card ${styles.cardCustom}`} onClick={() => navigate(`categories/${cat.id}?catName=${cat.name}`)}>
              <div className={`${styles.cardBodyCustom} card-body`}>
                {cat.name.toUpperCase()}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesPage;
