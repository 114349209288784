import React, {ChangeEvent, FC, useRef, useState} from 'react';
import {AnswerType3, QuizQuestion} from "../../shared/types";

type Props = {
  data: QuizQuestion
  isSubmitted: boolean
  answersState: AnswerType3[]
  setAnswersState: (state: AnswerType3[]) => void
}

const AudioQuestion: FC<{answer: AnswerType3}> = ({ answer }) => {
  const audio = useRef<HTMLAudioElement>(null)
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  
  const pronounceAudio = () => {
    const resp = audio.current?.play()
    if (resp!== undefined) {
      resp.then(_ => {
        // autoplay starts!
        setIsAudioPlaying(true);
      }).catch(error => {
        // show error
      });
    }
  }
  
  const pauseAudio = () => audio.current?.pause()
  
  return (
    <div>
      <audio ref={audio} hidden src={'https://saryarqatil.kz/edit/lib/audio/' + answer.file} onPause={() => setIsAudioPlaying(false)} />
      {isAudioPlaying ? (
        <i className="bi bi-pause-fill text-primary text-4xl cursor-pointer" onClick={pauseAudio}></i>
      ) : (
        <i className="bi bi-play-fill text-primary text-4xl cursor-pointer" onClick={pronounceAudio}></i>
      )}
    </div>
  )
}

export const CardTemplate7: FC<Props> = ({ data, isSubmitted, answersState, setAnswersState }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number ) => {
    const newState = answersState.map((item) => {
      if (item.id === id) {
        return { ...item, answer: e.target.value }
      } else return item
    })
    setAnswersState(newState)
  }
  
  return (
    <div className="text-center px-3">
      <h5 className="h5">{data.qs_main}</h5>
      <div className="my-5">
        <div className="row gx-5 gy-4 align-items-center">
          {answersState.map((answ) => {
            const inputColor = isSubmitted ?
              (data.paramjs as AnswerType3[]).find((item) => item.id === answ.id)?.answer.toLowerCase().trim() === answ.answer.toLowerCase().trim()
                ? "bg-success text-white" : "bg-danger" : "bg-white"
            
            return (
              <div key={answ.id} className="col-6">
                <div className="d-flex justify-center align-items-center gap-3">
                  <AudioQuestion answer={answ} />
                  <div className="flex-grow-1">
                    <input disabled={isSubmitted} className={`w-100 h-[34px] px-3 text-center rounded-1 border border-1 border-gray ${inputColor}`} value={answ.answer} onChange={(e) => handleChange(e, answ.id)} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};
