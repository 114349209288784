import React, {FC, useCallback} from 'react';
import {AnswerType3, DragCardItem, QuizQuestion} from "../../shared/types";
import update from "immutability-helper";
import {DndCard} from "../dndCard/dndCard";

type Props = {
  data: QuizQuestion
  isSubmitted: boolean
  answersState: AnswerType3[]
  dragAnswersState: DragCardItem[]
  setDragAnswersState: React.Dispatch<React.SetStateAction<DragCardItem[]>>
  isType5: boolean
}

export const CardTemplate8: FC<Props> = ({ data, isSubmitted, answersState, dragAnswersState, setDragAnswersState, isType5 }) => {
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    if (isSubmitted) return;
    setDragAnswersState((prevCards: DragCardItem[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as DragCardItem],
        ],
      }),
    )
  }, [isSubmitted])
  
  return (
    <div className="text-center px-3">
      <h5 className="h5">{data.qs_main}</h5>
      <div className="my-5">
        <div className="row gx-5 gy-3 align-items-stretch">
            <div className="col-6">
              <div className="h-full d-flex flex-column gap-3">
                {answersState.map((answ) => (
                  <div className="d-flex justify-center">
                    <img src={'https://saryarqatil.kz/edit/lib/pic/' + answ.file} alt={answ.file} width={200}/>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-6">
              <div className="h-full d-flex flex-column justify-around">
                {dragAnswersState.map((item, index) => {
                  const bgColor = isSubmitted ?
                    (data.paramjs as AnswerType3[])[index].answer.toLowerCase().trim() === dragAnswersState[index].text.toLowerCase().trim()
                      ? "bg-success text-white" : "bg-danger text-white" : "bg-white"
                  return (
                    <DndCard
                      key={item.id}
                      index={index}
                      id={item.id}
                      text={item.text}
                      moveCard={moveCard}
                      bgColor={bgColor}
                      isType5={isType5}
                    />
                  )
                })}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};
