import React, {ChangeEvent, FC} from 'react';
import {AnswerType3, QuizQuestion} from "../../shared/types";

type Props = {
  data: QuizQuestion
  isSubmitted: boolean
  answersState: AnswerType3[]
  setAnswersState: (state: AnswerType3[]) => void
}

export const CardTemplate6: FC<Props> = ({ data, isSubmitted, answersState, setAnswersState }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number ) => {
    const newState = answersState.map((item) => {
      if (item.id === id) {
        return { ...item, answer: e.target.value }
      } else return item
    })
    setAnswersState(newState)
  }
  return (
    <div className="text-center px-3">
      <h5 className="h5">{data.qs_main}</h5>
      <div className="my-5">
        <div className="row gx-3 gy-2 align-items-center">
          {answersState.map((answ) => {
            const originalAnswer = (data.paramjs as AnswerType3[]).find((item) => item.id === answ.id)?.answer
            const inputColor = isSubmitted ? originalAnswer?.toLowerCase().trim() === answ.answer.toLowerCase().trim() ? "bg-success text-white" : "bg-danger" : "bg-white"
            return (
              <div className="col-6">
                <div className="p-3">
                  <div className="d-flex justify-center mb-3">
                    <img src={'https://saryarqatil.kz/edit/lib/pic/' + answ.file} alt={answ.file} width={200}/>
                  </div>
                  <div>
                    <input disabled={isSubmitted} className={`w-100 h-[34px] px-3 text-center rounded-1 border border-1 border-gray ${inputColor}`} value={answ.answer} onChange={(e) => handleChange(e, answ.id)} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};
