import React, {FC, useEffect} from 'react';
import {QuizQuestion} from "../../shared/types";

type Props = {
  data: QuizQuestion
  isSubmitted: boolean
  currentAnswer: string
  setCurrentAnswer: (val: string) => void
  pronounceAudio: (isQuestionPlay?: boolean) => void
  pauseAudio: () => void
  isAudioPlaying: boolean
}

export const CardTemplate3: FC<Props> = ({ data, isSubmitted, currentAnswer, setCurrentAnswer, pronounceAudio, pauseAudio, isAudioPlaying }) => {
  const splittedQuestion = data.qs_dsc?.split("*")
  
  const handleChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(e.target.value);
    setCurrentAnswer(e.target.value)
  }
  
  useEffect(() => {
    pronounceAudio(true);
  }, [data.id])
  
  return (
    <div className="text-center">
      <h5 className="h5">{data.qs_main}</h5>
      <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
        <p className="text-xl">{splittedQuestion?.[0]}</p>
        <input disabled={isSubmitted} className="w-[50%] h-[34px] text-center border border-1 border-gray bg-white rounded-1 px-2 py-1" value={currentAnswer} onChange={handleChange} />
        <p className="text-xl">{splittedQuestion?.[1]}</p>
        {isAudioPlaying ? (
          <i className="bi bi-pause-fill text-primary text-4xl cursor-pointer" onClick={pauseAudio}></i>
        ) : (
          <i className="bi bi-play-fill text-primary text-4xl cursor-pointer" onClick={() => pronounceAudio(true)}></i>
        )}
      </div>
    
      <div className="btn-group my-5 d-flex justify-content-center gap-3">
        {Object.values(data.paramjs).map((answ) => {
          const isAnswerCorrect = answ.correct === "true"
          return (
            <div className={"rounded-1 py-1 px-3 " + (isSubmitted && answ.value === currentAnswer ? isAnswerCorrect ? "bg-success border-0 text-white" : "bg-danger text-white border-0" : "border border-1 border-gray bg-white")}>{answ.value}</div>
          )
        })}
      </div>
    </div>
  );
};
