import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <header className="bg-[#F3F3F3] shadow-lg p-[16px]">
      <div className="container mx-auto flex justify-between items-center gap-2">
        <a href="/">
          <img src="/images/saryarqa-logo.png" alt="Logo" width={228} />
        </a>
        <nav className="hidden lg:flex gap-5 font-semibold">
          <a href="#" className="no-underline text-[#5F90C6]">Басты бет</a>
          <a href="#" className="no-underline text-[#5F90C6]">Оқу-әдістемелік кешен</a>
        </nav>
        <div className="hidden lg:flex items-center gap-3">
          <a href="#" className="text-white">
            <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#55acee] rounded-[4px]">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </div>
          </a>
          <a href="#" className="text-white">
            <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#AB1786] rounded-[4px]">
              <FontAwesomeIcon icon={faInstagram} />
            </div>
          </a>
          <a href="#" className="text-white">
            <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#DD1212] rounded-[4px]">
              <FontAwesomeIcon icon={faYoutube} />
            </div>
          </a>
        </div>
        <div className="hidden lg:flex items-center gap-3">
          <button className="btn !bg-[#5F90C6] text-white px-4">Кіру</button>
          <button className="btn !bg-[#5F90C6] text-white px-4">Тіркелу</button>
          <p className="mb-0"><b>KZ</b>/RU/EN</p>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="lg:hidden flex flex-col items-center gap-3 pt-4 font-semibold">
          <a href="#" className="no-underline">Басты бет</a>
          <a href="#" className="no-underline">Оқу-әдістемелік кешен</a>
          <div className="flex items-center gap-3">
            <a href="#" className="text-white">
              <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#55acee] rounded-[4px]">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </div>
            </a>
            <a href="#" className="text-white">
              <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#AB1786] rounded-[4px]">
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </a>
            <a href="#" className="text-white">
              <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#DD1212] rounded-[4px]">
                <FontAwesomeIcon icon={faYoutube} />
              </div>
            </a>
          </div>
          <button className="btn !bg-[#5F90C6] text-white px-4">Кіру</button>
          <button className="btn !bg-[#5F90C6] text-white px-4">Тіркелу</button>
          <p className="mb-0"><b>KZ</b>/RU/EN</p>
        </div>
      )}
    </header>
  );
};

export default Header;
