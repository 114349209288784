import React, {FC} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

type Props = {
  chapterName: string
  videoId: string
}

const LessonModal: FC<Props> = ({ chapterName, videoId }) => {
  return (
    <div className="modal fade" id="lessonModal" tabIndex={-1} role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">{chapterName}</h5>
            <button type="button" className="close ml-auto fs-5" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="aspect-video">
              <iframe width="100%" height="100%" src={`//www.youtube-nocookie.com/embed/${videoId}?autoplay=0&showinfo=0&controls=0&rel=0`} frameBorder={0} title={chapterName as string} allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonModal;
