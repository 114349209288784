import React, {useEffect, useState} from 'react';
import {Chapter} from "../../shared/types";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import styles from './chaptersPage.module.scss';
import Cookies from "js-cookie";

const ChaptersPage = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [searchParams] = useSearchParams();
  const [chapters, setChapters] = useState<Chapter[]>([])
  
  useEffect(() => {
    (async () => {
      fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(  {
          command: "update_list_type",
          tmp_id: categoryId,
          tmp_table: "tbl_topic",
        })})
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setChapters(data)
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="container pt-[36px]">
      <button type="button" className="btn btn-outline-secondary btn-lg mb-3" onClick={() => navigate(-1)}>
        <i className="bi bi-chevron-left mr-2"></i>
        Кері оралу
      </button>
      <div className={styles.headCard}>{searchParams.get('catName')?.toUpperCase()}</div>
      <div className="row">
        {chapters.length > 0 ? chapters.map((chapter) => {
          const isPassed = Cookies.get(`chapter-${chapter.id}`)
          return (
            <div key={chapter.id} className="col-12 col-md-6 col-lg-4 mb-4">
              <div className={`card ${styles.cardCustom}`} key={chapter.id} onClick={() => navigate(`chapters/${chapter.id}?chapterName=${chapter.name}&linkVideo=${chapter.link_video}`)}>
                <div className={`${styles.cardBodyCustom} card-body ${isPassed ? isPassed === "true" ? "!border-t-[#28a745]" : "!border-t-[#dc3545]" : ""}`}>
                  {chapter.name?.toUpperCase()}
                </div>
              </div>
            </div>
          )
        }) : (
          <div className="text-center h5 pt-3">деректер жоқ</div>
        )}
      </div>
    </div>
  );
};

export default ChaptersPage;
