import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import "bootstrap/dist/js/bootstrap.min.js";
import App from './App';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="bgMain" />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
