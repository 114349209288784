import React, {FC, useCallback} from 'react';
import {AnswerType1, AnswerType4, DragCardItem, QuizQuestion} from "../../shared/types";
import update from "immutability-helper";
import {DndCard} from "../dndCard/dndCard";

type Props = {
  data: QuizQuestion
  isSubmitted: boolean
  answersState: AnswerType4[]
  dragAnswersState: DragCardItem[]
  setDragAnswersState: React.Dispatch<React.SetStateAction<DragCardItem[]>>
  isType5: boolean
}

export const CardTemplate5: FC<Props> = ({ data, isSubmitted, answersState, dragAnswersState, setDragAnswersState, isType5 }) => {
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    if (isSubmitted) return;
    setDragAnswersState((prevCards: DragCardItem[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as DragCardItem],
        ],
      }),
    )
  }, [isSubmitted])
  
  return (
    <div className="text-center px-3">
      <h5 className="h5">{data.qs_main}</h5>
      <div className="my-5 d-flex flex-column gap-3">
        <div className="row gx-5 gy-3 align-items-stretch">
          <div className="col-6">
            <div className="h-full d-flex flex-column justify-around gap-3">
              {answersState.map((answ) => (
                <div className="border border-1 border-gray bg-white rounded-1 p-2 px-3 text-nowrap text-center">
                  {answ.value}
                </div>
              ))}
            </div>
          </div>
          <div className="col-6">
            <div className="h-full d-flex flex-column justify-between gap-3">
              {dragAnswersState.map((item, index) => {
                const bgColor = isSubmitted ?
                  (data.paramjs as AnswerType1)[`${index + 1}`].correct.toLowerCase().trim() === dragAnswersState[index].text.toLowerCase().trim()
                    ? "bg-success text-white" : "bg-danger text-white" : "bg-white"
                return (
                  <DndCard
                    key={item.id}
                    index={index}
                    id={item.id}
                    text={item.text}
                    moveCard={moveCard}
                    bgColor={bgColor}
                    isType5={isType5}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
