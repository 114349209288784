import React from 'react';
import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Route, Routes} from "react-router-dom";
import CategoriesPage from "./pages/categoriesPage/categoriesPage";
import ChaptersPage from "./pages/chaptersPage/chaptersPage";
import ChaptersDetailsPage from "./pages/chapterDetailsPage/chapterDetailsPage";
import {QuizPage} from "./pages/quizPage/quizPage";
import Header from "./components/header/header";
import {Footer} from "./components/footer/footer";

function App() {
  return (
    <>
      <Header />
      <div className="min-h-[calc(100vh-182.62px)]">
        <Routes>
          <Route element={<CategoriesPage />} path={'/'} />
          <Route element={<ChaptersPage />} path={'categories/:categoryId'} />
          <Route element={<ChaptersDetailsPage />} path={'/categories/:categoryId/chapters/:chapterId'} />
          <Route element={<QuizPage />} path={'/categories/:categoryId/chapters/:chapterId/quiz'} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
