import React, {FC, useEffect, useRef, useState} from 'react';
import {AnswerType2, QuestionType, QuizQuestion} from "../../shared/types";
import {CardTemplate1} from "../cardTemplates/cardTemplate1";
import {CardTemplate2} from "../cardTemplates/cardTemplate2";
import {CardTemplate3} from "../cardTemplates/cardTemplate3";
import {CardTemplate4} from "../cardTemplates/cardTemplate4";

type Props = {
  data: QuizQuestion
  handleAnswer: (id: string, isCorrect: boolean) => void
  isSubmitted: boolean
  handleChangeQuestion: () => void
}
export const SingleAnswerCard: FC<Props> = ({ data, handleAnswer, isSubmitted, handleChangeQuestion }) => {
  const isType4 = data.qs_type === QuestionType.Four
  const type4Answer = (data.paramjs as AnswerType2)["1"].correct;
  const otherTypesAnswer = Object.values(data.paramjs).find((item) => item.correct === "true")?.value;
  const [currentAnswer, setCurrentAnswer] = useState<string>("")
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  const cardAudio = useRef<HTMLAudioElement>(null)
  
  const isCorrect = isType4 ? currentAnswer.toLowerCase().trim() === type4Answer.toLowerCase().trim() : currentAnswer.toLowerCase().trim() === otherTypesAnswer.toLowerCase().trim()
  
  const pronounceAudio = (isQuestionPlay = false) => {
    const resp = cardAudio.current?.play()
    if (resp!== undefined) {
      resp.then(_ => {
        // autoplay starts!
        if (isQuestionPlay) {
          setIsAudioPlaying(true);
        }
      }).catch(() => {
        //show error
      });
    }
  }
  
  const pauseAudio = () => cardAudio.current?.pause()
  
  const handleSubmit = () => {
    pronounceAudio();
    handleAnswer(data.id, isCorrect)
  }
  
  useEffect(() => {
    setCurrentAnswer("")
  }, [data.id])
  
  return (
    <div className="border border-1 border-black p-5 max-w-[600px] md:min-w-[500px] rounded-2 text-center bg-light shadow-2xl">
      <audio ref={cardAudio} hidden src={'https://saryarqatil.kz/edit/lib/audio/' + data.main_audio} onPause={() => setIsAudioPlaying(false)} />
      {data.qs_type === QuestionType.One && <CardTemplate1 data={data} isSubmitted={isSubmitted} currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} />}
      {data.qs_type === QuestionType.Two && <CardTemplate2 data={data} isSubmitted={isSubmitted} currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} />}
      {data.qs_type === QuestionType.Three &&
        <CardTemplate3 data={data} isSubmitted={isSubmitted} currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} pronounceAudio={pronounceAudio} isAudioPlaying={isAudioPlaying} pauseAudio={pauseAudio} />}
      {isType4 &&
        <CardTemplate4 data={data} isSubmitted={isSubmitted} currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} pronounceAudio={pronounceAudio} isAudioPlaying={isAudioPlaying} pauseAudio={pauseAudio} />}
  
      {!isSubmitted ? (
        <button className="btn btn-primary px-5 font-bold" onClick={handleSubmit}>ТЕКСЕРУ</button>
      ) : (
        <button className="btn btn-outline-primary px-5 font-bold" onClick={handleChangeQuestion}>КЕЛЕСІ</button>
      )}
      {isSubmitted && (
        <div className={"d-flex justify-content-center align-items-center gap-2 border border-1 border-gray mt-3 p-3 " + (isCorrect ? "bg-success" : "bg-danger")}>
          <p className="text-white mb-1">{isType4 ? type4Answer : !!data.qs_dsc ? data.qs_dsc.replace("*", otherTypesAnswer) : otherTypesAnswer}</p>
          <i className={"bi bi-volume-up-fill text-white text-2xl cursor-pointer hover:scale-125 transition ease-in-out delay-150"} onClick={() => pronounceAudio()}></i>
        </div>
      )}
    </div>
  );
};
