import React from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import styles from './chapterDetailsPage.module.scss';

const ChapterDetailsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const chapterName = searchParams.get('chapterName');
  const linkVideo = searchParams.get('linkVideo');
  
  function getId(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    
    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  const videoId = getId(linkVideo as string);
  
  return (
    <div className="container pt-[36px] pb-5">
      <button type="button" className="btn btn-outline-secondary btn-lg mb-3" onClick={() => navigate(-1)}>
        <i className="bi bi-chevron-left mr-2"></i>
        Кері оралу
      </button>
      <div className={styles.headCard}>{chapterName?.toUpperCase()}</div>
      <div className="aspect-video">
        { videoId ? (
          <iframe width="100%" height="100%" src={`//www.youtube-nocookie.com/embed/${videoId}?autoplay=0&showinfo=0&controls=0&rel=0`} frameBorder={0} title={chapterName as string} allowFullScreen></iframe>
          ) : (
          <div className="text-center h5 pt-3">деректер жоқ</div>
        )}
      </div>
      <div className="d-flex flex-column align-items-center gap-3 mt-4">
        <button type="button" className="btn btn-success btn-lg px-5 py-2" onClick={() => navigate(`quiz?chapterName=${chapterName}&videoId=${videoId}`)}>ТЕСТТІ БАСТАУ</button>
      </div>
    </div>
  );
};

export default ChapterDetailsPage;
