import React, {FC, useEffect, useState} from 'react';
import {AnswerType3, AnswerType4, DragCardItem, QuestionType, QuizQuestion} from "../../shared/types";
import {CardTemplate5} from "../cardTemplates/cardTemplate5";
import {CardTemplate8} from "../cardTemplates/cardTemplate8";
import {CardTemplate6} from "../cardTemplates/cardTemplate6";
import {CardTemplate7} from "../cardTemplates/cardTemplate7";
import {shuffleAnswersType5, shuffleAnswersType8} from "../../shared/utils";
import {DndProvider} from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'

type Props = {
  data: QuizQuestion
  handleAnswer: (id: string, isCorrect: boolean) => void
  isSubmitted: boolean
  handleChangeQuestion: () => void
}
export const MultipleAnswerCard: FC<Props> = ({ data, handleAnswer, handleChangeQuestion, isSubmitted }) => {
  const isType5 = data.qs_type === QuestionType.Five
  const isType6 = data.qs_type === QuestionType.Six
  const isType7 = data.qs_type === QuestionType.Seven
  const isType8 = data.qs_type === QuestionType.Eight
  
  const [answersState, setAnswersState] = useState<AnswerType3[]>([])
  const [type5AnswersState, setType5AnswersState] = useState<AnswerType4[]>([])
  const [dragAnswersState, setDragAnswersState] = useState<DragCardItem[]>([])
  
  const checkIsIncorrect = () => {
    if (isType5) {
      const values = Object.values(data.paramjs);
      return values.some((item, index) => item.correct.toLowerCase().trim() !== dragAnswersState[index].text.toLowerCase().trim())
    } else if (isType8) {
      const values = data.paramjs as AnswerType3[]
      return values.some((item, index) => item.answer.toLowerCase().trim() !== dragAnswersState[index].text.toLowerCase().trim())
    } else {
      const values = data.paramjs as AnswerType3[]
      return values.some((item, index) => item.answer.toLowerCase().trim() !== answersState[index].answer.toLowerCase().trim())
    }
  }
  
  const handleSubmit = () => {
    const isIncorrect = checkIsIncorrect();
    handleAnswer(data.id, !isIncorrect)
  }
  
  useEffect(() => {
    if (isType5) {
      const values = Object.values(data.paramjs);
      const mixedValues = shuffleAnswersType5(values)
      setDragAnswersState(mixedValues.map((item, index) => ({ id: index, text: item.correct })))
      setType5AnswersState(mixedValues)
    } else if (isType8) {
      const values = data.paramjs as AnswerType3[]
      const mixedValues = shuffleAnswersType8(values)
      setDragAnswersState(mixedValues.map((item, index) => ({ id: item.id, text: item.answer })))
      setAnswersState(mixedValues)
    } else {
      setAnswersState((data.paramjs as AnswerType3[]).map((item) => ({ ...item, answer: "" })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])
  
  useEffect(() => {
    console.log(dragAnswersState, answersState)
  }, [dragAnswersState])
  
  return (
    <div className="border border-1 border-black p-4 max-w-[600px] rounded-2 text-center bg-light shadow-2xl">
      {isType6 && <CardTemplate6 data={data} isSubmitted={isSubmitted} answersState={answersState} setAnswersState={setAnswersState} />}
      {isType7 && <CardTemplate7 data={data} isSubmitted={isSubmitted} answersState={answersState} setAnswersState={setAnswersState} />}
      <DndProvider backend={HTML5Backend}>
        {isType5 && <CardTemplate5 isType5={isType5} data={data} isSubmitted={isSubmitted} answersState={type5AnswersState} dragAnswersState={dragAnswersState} setDragAnswersState={setDragAnswersState} />}
        {isType8 && <CardTemplate8 isType5={isType5} data={data} isSubmitted={isSubmitted} answersState={answersState} dragAnswersState={dragAnswersState} setDragAnswersState={setDragAnswersState} />}
      </DndProvider>
      {!isSubmitted ? (
        <button className="btn btn-primary px-5 font-bold" onClick={handleSubmit}>ТЕКСЕРУ</button>
      ) : (
        <button className="btn btn-outline-primary px-5 font-bold" onClick={handleChangeQuestion}>КЕЛЕСІ</button>
      )}
    </div>
  );
};
