import React, {FC} from 'react';
import {QuizQuestion} from "../../shared/types";

type Props = {
  data: QuizQuestion
  isSubmitted: boolean
  currentAnswer: string
  setCurrentAnswer: (val: string) => void
}

export const CardTemplate1: FC<Props> = ({ data, isSubmitted, currentAnswer, setCurrentAnswer }) => {
  
  const handleChange = (e:  React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (e.target.checked) {
      setCurrentAnswer(value);
      setCurrentAnswer(value)
    }
  }
  
  return (
    <div className="text-center">
      <h5 className="h5">{data.qs_main}</h5>
      <p className="mt-5 text-xl">{data.qs_dsc.replace("*", "...")}</p>
    
      <div className="btn-group my-5 d-flex justify-content-around gap-3" role="group" aria-label="Basic radio toggle button group">
        {Object.values(data.paramjs).map((answ) => {
          const isAnswerCorrect = answ.correct === "true"
          return (
            <React.Fragment key={answ.value + data.id}>
              <input type="radio" className="btn-check" name="btnradio" id={"cardAnswer-" + answ.value} autoComplete="off" onChange={(e) => handleChange(e, answ.value)} disabled={isSubmitted && answ.value !== currentAnswer} />
              <label className={"btn btn-outline-primary rounded-2 font-medium " + (isSubmitted && answ.value === currentAnswer ? isAnswerCorrect ? "bg-success border-0 text-white" : "bg-danger border-0" : "")} htmlFor={"cardAnswer-" + answ.value}>{answ.value}</label>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  );
};
