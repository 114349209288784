function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const shuffleAnswersType5 = (questions: any[]) => {
  const answers = questions.map(q => q.correct);
  
  shuffle(answers);
  const incorrectAnswers = [...answers];
  
  const firstAnswer = incorrectAnswers.shift();
  incorrectAnswers.push(firstAnswer as string);
  
  return questions.map((q, index) => ({
    ...q,
    correct: incorrectAnswers[index]
  }));
}

export const shuffleAnswersType8 = (questions: any[]) => {
  const answers = questions.map(q => q.answer);
  
  shuffle(answers);
  const incorrectAnswers = [...answers];
  
  const firstAnswer = incorrectAnswers.shift();
  incorrectAnswers.push(firstAnswer as string);
  
  return questions.map((q, index) => ({
    ...q,
    answer: incorrectAnswers[index]
  }));
}
