export enum QuestionType {
  One = 'type_1',
  Two = 'type_2',
  Three = 'type_3',
  Four = 'type_4',
  Five = 'type_5',
  Six = 'type_6',
  Seven = 'type_7',
  Eight = 'type_8',
  Nine = 'type_9',
}
export type AnswerType1 = {
  [key: string]: {
    correct: string
    value: string
  };
}
export type AnswerType2 = {
  "1": {
    correct: string
  };
}
export type AnswerType3 = {
  answer: string
  file: string
  id: number
}
export type AnswerType4 = {
  correct: string
  value: string
}
export type QuizQuestion = {
  id: string
  id_topic: string
  main_audio: string
  paramjs: AnswerType1 | AnswerType2 | AnswerType3[]
  qs_dsc: string
  qs_main: string
  qs_type: QuestionType
  isCorrect: boolean
}

export type Category = {
  id: string
  name: string
}

export type Chapter = {
  id: string
  id_type: string
  name: string
  link_video: string
}

export interface DragCardItem {
  id: number
  text: string
}
