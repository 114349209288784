import React, {FC, useEffect} from 'react';
import {QuizQuestion} from "../../shared/types";

type Props = {
  data: QuizQuestion
  isSubmitted: boolean
  currentAnswer: string
  setCurrentAnswer: (val: string) => void
  pronounceAudio: (isQuestionPlay?: boolean) => void
  pauseAudio: () => void
  isAudioPlaying: boolean
}

export const CardTemplate4: FC<Props> = ({ data, isSubmitted, currentAnswer, setCurrentAnswer, pronounceAudio, pauseAudio, isAudioPlaying }) => {
  const handleChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(e.target.value);
    setCurrentAnswer(e.target.value)
  }
  
  useEffect(() => {
    pronounceAudio(true);
  }, [data.id])
  
  return (
    <div className="text-center">
      <h5 className="h5">{data.qs_main}</h5>
      <div className="mt-5 text-center">
        {isAudioPlaying ? (
          <i className="bi bi-pause-fill text-primary text-4xl cursor-pointer" onClick={pauseAudio}></i>
        ) : (
          <i className="bi bi-play-fill text-primary text-4xl cursor-pointer" onClick={() => pronounceAudio(true)}></i>
        )}
      </div>
      <div className="mt-3 mb-5 text-center">
        <input disabled={isSubmitted} className="w-[200px] h-[34px] px-2 py-1 text-center border border-1 border-gray bg-white rounded-1" value={currentAnswer} onChange={handleChange} />
      </div>
    </div>
  );
};
