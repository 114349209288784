import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInstagram, faFacebookSquare, faYoutube} from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
    <footer>
      <div className="bg-[#F3F3F3] p-[20px] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)]">
        <div className="container mx-auto d-flex justify-between items-center">
          <a href="/">
            <img src="/images/saryarqa-logo.png" alt="Logo" width={228} />
          </a>
          <div className="hidden lg:flex items-center gap-3">
            <a href="#" className="text-white">
              <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#55acee] rounded-[4px]">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </div>
            </a>
            <a href="#" className="text-white">
              <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#AB1786] rounded-[4px]">
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </a>
            <a href="#" className="text-white">
              <div className="w-[35px] h-[35px] d-flex justify-center items-center bg-[#DD1212] rounded-[4px]">
                <FontAwesomeIcon icon={faYoutube} />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-[#1C3C5F]">
        <p className="container mx-auto text-white mb-0 text-sm">© Қарағанды 2024 ж. Барлық құқықтар қорғалған.</p>
      </div>
    </footer>
  );
};
